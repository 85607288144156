import { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import ArrowDownIcon from 'icons/arrow-down.svg';
import styles from '../../../../styles/login/Industry.module.css';
import Divider from '@material-ui/core/Divider';
import Select from 'components/UI/Inputs/Select';
import classes from './form.module.scss';
import { Flag } from 'country-list-with-dial-code-and-flag/dist/types';
import UserEntity from 'src/features2/user/domain/entities/User';
import useAmplitude from 'src/analytics/amplitude/ui/useAmplitude';

interface FormValues {
  companyName: string;
  companyWebsite: string;
  companySize: string;
  phoneCode: string;
  phoneNumber: string;
}
interface FormProps {
  findCountryByCode: (code: any) => Flag;
  phoneCodes: Flag[];
  user: UserEntity;
  disabledSubmit: boolean;
  handleChange: (data: any) => void;
  handleSubmit: (data: any) => void;
  defaultValues: FormValues;
}

const OnboardingForm = ({
  phoneCodes,
  findCountryByCode,
  defaultValues,
  user,
  handleChange,
  handleSubmit,
  disabledSubmit,
}: FormProps) => {
  const { trackEvent } = useAmplitude();
  const { t } = useTranslation('login-page, common');
  const {
    handleSubmit: handleSubmitForm,
    watch,
    control,
    setValue,
    reset,
  } = useForm<FormValues>({
    defaultValues,
  });
  useEffect(() => {
    trackEvent('view_onboarding_questions');
  }, []);
  const companyName = watch('companyName');
  const phoneCode = watch('phoneCode');
  const phoneNumber = watch('phoneNumber');
  const companyWebsite = watch('companyWebsite');
  const companySize = watch('companySize');
  const currentPhoneCodeData = findCountryByCode(phoneCode);
  const onSubmit = (data: FormValues) => {
    handleSubmit(data);
  };
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    handleChange({
      companyName,
      phoneCode,
      phoneNumber,
      companyWebsite,
      companySize,
    });
  }, [companyName, phoneCode, phoneNumber, companyWebsite, companySize, handleChange]);
  if (!user) return null;
  return (
    <div className={styles.IndustryWrapper}>
      <Typography component={'h2'} className={styles.stepMainTitle}>
        {t(
          user.platforms.some((platform) => platform === 'ios' || platform === 'android')
            ? 'login-page:onboarding.title_existed'
            : 'login-page:onboarding.title_new',
          {
            userName: user.fullName,
            appName: process.env.NEXT_PUBLIC_APP_NAME,
          }
        )}
      </Typography>
      <Typography variant={'h2'} className={styles.stepTitle}>
        {t('login-page:onboarding.stepTitle')}
      </Typography>
      <Typography component={'p'} className={styles.stepDescription}>
        {t('login-page:onboarding.stepDescription')}
      </Typography>
      <form className={styles.form} onSubmit={handleSubmitForm(onSubmit)}>
        <Controller
          control={control}
          name="companyName"
          render={({ field: { onChange, value } }) => (
            <FormControl className={classes.margin} required>
              <InputLabel className={styles.label} shrink htmlFor="company_name">
                {t('login-page:onboarding.company.title')}
              </InputLabel>
              <InputBase
                value={value}
                className={styles.inputBase}
                onChange={onChange}
                inputProps={{ 'data-hj-allow': '' }}
              />
            </FormControl>
          )}
        />
        <div className={styles.row}>
          <Controller
            control={control}
            name="companySize"
            render={({ field: { onChange, value } }) => (
              <FormControl className={clsx(classes.margin, styles.halfInputWrapper)} required>
                <InputLabel className={styles.label} shrink htmlFor="companySize">
                  {t('login-page:onboarding.peopleWork.label')}
                </InputLabel>
                <Select
                  className={clsx(styles.select, classes.inputCompanySize)}
                  value={value}
                  onChange={onChange}
                  options={Object.entries(
                    t('login-page:onboarding.peopleWork.optionValues', { returnObjects: true })
                  ).map(([value, label]) => {
                    return { value, text: label };
                  })}
                />
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="companyWebsite"
            render={({ field: { onChange, value } }) => (
              <FormControl className={clsx(classes.margin, styles.halfInputWrapper)}>
                <InputLabel className={styles.label} shrink htmlFor="website">
                  {t('login-page:onboarding.companyWebsite.label')}
                </InputLabel>
                <InputBase
                  value={value}
                  className={styles.inputBase}
                  onChange={onChange}
                  inputProps={{ 'data-hj-allow': '' }}
                />
              </FormControl>
            )}
          />
        </div>
        <h4 className={classes.phoneNumberLabel}>
          {t('login-page:onboarding.businessPhone.label')} *
        </h4>

        <div className={styles.autocompleteWrapper}>
          <button type="button" className={classes.buttonPhoneCode}>
            <div className={classes.flagWrapper}>
              <span className={classes.flag}>{currentPhoneCodeData?.flag}</span>
            </div>
            <div className={classes.phoneCode}>
              <span className={classes.countryName}>{currentPhoneCodeData?.code}</span>{' '}
              <span className={classes.code}>{currentPhoneCodeData?.dial_code}</span>
            </div>
            <select
              className={classes.phoneCodeSelect}
              onChange={(e) => {
                setValue('phoneCode', e.target.value);
              }}
            >
              {phoneCodes.map((code) => (
                <option key={code.name} value={code.dial_code}>
                  {code.name.replace('of the', '').replace('of', '')} ({code.dial_code})
                </option>
              ))}
            </select>
            <ArrowDownIcon />
          </button>
          <Divider
            orientation="vertical"
            flexItem
            variant={'middle'}
            className={classes.phoneDivider}
          />
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field: { onChange, value } }) => (
              <InputBase
                value={value}
                placeholder={t('login-page:onboarding.businessPhone.placeholder')}
                className={classes.phoneInput}
                onChange={onChange}
                inputProps={{ 'data-hj-allow': '' }}
              />
            )}
          />
        </div>

        <Button
          variant="contained"
          disabled={disabledSubmit}
          className={clsx(styles.submit, classes.submit)}
          type="submit"
        >
          {t('login-page:onboarding.buttonTitle')}
        </Button>
      </form>
    </div>
  );
};

export default OnboardingForm;
